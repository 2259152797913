body {
    color: $color_grey;
    font-family: $font_family;
    font-size: 13px;
}

h1 {
    margin: 0;
    small {
        color: $color_grey_light;
        font-weight: normal;
    }
}

h2 {
    font-size: 18px;
    margin: 0;
    small {
        color: $color_grey_light;
        font-weight: normal;
    }
}

h3 {
    font-size: 16px;
    margin: 0;
    small {
        color: $color_grey_light;
        font-weight: normal;
    }
}

a {
    color: $color_grey;
    &:hover {
        color: $color_grey;
    }
}

p {
    margin: 5px 0px;
}

.left {
    text-align: left;
    input {
        text-align: left;
    }
}

.center {
    text-align: center;
    input {
        text-align: center;
    }
}

.right {
    text-align: right;
    input {
        text-align: right;
    }
}

pre {
    color: $color_grey;
    font-family: $font_family;
    font-size: 13px;
    background: transparent;
    border: none;
    padding: 0;
    margin: 5px 0;
    vertical-align: top;
    display: inline-block;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.info {
    color: $brand_primary;
    font-size: 12px;
    font-weight: normal;
}