$bg: #f1f3f6;
$bg_grey_light: #fafafa;

$font_family: 'Helvetica', sans-serif;

$color_grey: #484746;
$color_grey_light: #838D94;

$brand_primary:     #3aa3e3;
$brand_secondary:   #48606f;
$brand_success:     #748885;
$brand_danger: #E75650;

$light_border: rgba(63, 63, 68, .1);
$grey_border: #eee;

$status_grey: #777777;
$status_light_blue: #8EB4CB;
$status_green: #64BD63;
$status_red:  #E75650;
$status_blue: #3aa3e3;
$status_orange: #FF9800;