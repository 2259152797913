.table {
    width: 100%;
    border-collapse: collapse;
    thead {
        tr {
            th {
                text-align: left;
                border-bottom: 2px solid $grey_border;
                padding: 8px!important;
            }
        }
    }
    tbody {
        tr {
            border-top: 1px solid $grey_border;
            td {
                vertical-align: top;
                padding: 8px!important;
            }
            &:nth-of-type(odd) {
                background-color: #fcfcfc;
            }
        }
    }
}

.table-link {
    tbody {
        td {
            cursor: pointer;
        }
    }
}

.table-summary {
    width: 100%;
    border-collapse: collapse;
    tbody {
        tr {
            td {
                font-weight: bold;
                padding: 0 0 10px 0;
                &:nth-child(2n) {
                    font-weight: normal;
                    text-align: right;
                }
            }
        }
    }
}

.table-dark {
    margin-top: 15px;
    thead {
        th {
            background: $color_grey;
            border: 1px solid $color_grey;
            border-bottom: 2px solid #333!important;
            color: #fff;
        }
    }
    tbody {
        td {
            border: 1px solid $color_grey;
        }
    }
    tfoot {
        border-top: 1px solid #e0e0e0;
        tr {
            border-bottom: 1px solid #e0e0e0;
            td {
                background: #f5f4f3;
                border: 1px solid $color_grey;
                padding: 8px!important;
            }
        }
    }
}