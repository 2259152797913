.dropdown {
    position: relative;
    display: inline-block;
    margin-left: 1px;
    &-btn {
        .fa {
            margin-left: 5px;
        }
    }
    &-active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    &-list {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        border: 1px solid lighten(#555, 52%);
        border-radius: 3px;
        min-width: 160px;
        background: #fff;
        position: absolute;
        bottom: 1;
        right: 0;
        margin: 5px 0 0 0;
        padding: 5px;
        li {
            display: block;
            margin-bottom: 1px;
            a {
                border-radius: 2px;
                display: block;
                padding: 7px;
                &:hover {
                    background: $brand_primary;
                    color: #fff;
                }
            }
        }
    }
}