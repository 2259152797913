*, :after, :before {
    box-sizing: border-box;
}
html {
    height: 100%;
}

body {
    background: $bg;
    height: 100%;
}

a {
    cursor: pointer;
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
}