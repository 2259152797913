.form {
    label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        font-weight: bold;
        small {
            color: $color_grey_light;
            font-weight: normal;
        }
    }
    &-group,
    &-checkbox {
        padding: 8px 0px;
    }
}
.form-control {
    width: 100%;
    display: block;
    background: $bg_grey_light;
    border: none;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px 0 rgba(0,0,0,.1);
    outline: none;
    padding: 8px;
}

textarea.form-control {
    height: 97px;
    resize: none;
}

select.form-control,
input.form-control {
    height: 30px;
}

.form-checkbox label {
    font-weight: normal;
}

.form-checkbox input {
    margin-right: 2px;
}

.form-control {
    &[disabled] {
        cursor: not-allowed;
    }
}

.form_table {
    width: 100%;
    border-collapse: collapse;
    thead {
        tr {
            th {
                text-align: left;
                border-bottom: 2px solid $grey_border;
                padding: 8px;
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 0px;
                position: relative;
            }
        }
    }
    tfoot {
        tr {
            td {
                padding: 0px;
            }
        }
    }
    &-control {
        border: none;
        margin: 0;
        display: block;
        width: 100%;
        background: $bg_grey_light;
        box-shadow: inset 0 1px 1px 0 rgba(0,0,0,.1);
        height: 50px;
        padding: 8px;
        outline: none;
        resize: none;
    }
    &-text {
        display: block;
        width: 100%;
        background: $bg_grey_light;
        box-shadow: inset 0 1px 1px 0 rgba(0,0,0,.1);
        height: 50px;
        padding: 19px 8px;
    }
    &-delete {
        button {
            background: transparent;
            border: none;
            outline: none;
            color: $brand_danger;
            cursor: pointer;
            font-weight: bold;
        }
    }
}

.e-table {
    input, textarea {
        background: lighten($brand_danger, 35%);
    }
}

.e-text {
    display: block;
    margin-top: 2px;
    color: $brand_danger;
}

.form_table-total {
    background: #f5f5f5;
    text-align: right;
}

.form_table-title {
    background: #f5f5f5;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    span {
        padding: 8px;
    }
}

.form_table-btns {
    padding: 10px 0px;
}