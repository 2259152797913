.btn {
    border: none;
    border-radius: 3px;
    font-size: 13px;
    height: 30px;
    padding: 8px 16px;
    position: relative;
    text-shadow: none;
    vertical-align: top;
    cursor: pointer;
    background: #f4f4f4;
    color: $color_grey;
    box-shadow: 0 1px darken(#f4f4f4, 13%);
    &:hover, &:active, &:focus, &:active:focus {
        border: none;
        outline: none;
    }
    &:active {
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    }
    &[disabled] {
        opacity: 0.75;
        cursor: not-allowed;
    }
}
a.btn {
    display: inline-block;
    text-decoration: none;
    line-height: 1.15;
    color: $color_grey;
    &:hover, &:active {
        text-decoration: none;
    }
}

a.btn-primary,
a.btn-secondary,
a.btn-danger {
    color: #fff;
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-primary {
    background: $brand_primary;
    color: #fff;
    box-shadow: 0 1px darken($brand_primary, 13%);
}

.btn-secondary {
    background: $brand_secondary;
    color: #fff;
    box-shadow: 0 1px darken($brand_secondary, 13%);
}

.btn-danger {
    background: $brand_danger;
    color: #fff;
    box-shadow: 0 1px darken($brand_danger, 13%);
}

.btn-group {
    display: inline-block;
    padding-right: 5px;
}