.sortable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &-direction {
        color: $color_grey_light;
        font-size: 11px;
        line-height: 11px;
    }
}

.filter {
    padding-bottom: 10px;
}

.profile {
}
.profile-item {
    display: flex;
    margin-bottom: 15px;
    margin-top: 0px;
    i {
        flex: 1;
        color: $color_grey_light;
    }

    span, pre {
        flex: 9;
    }
}

.sidebar {
    background: #2D393C;
    /*background: pink;*/
    padding-left: 15px;
    &-group {
        /*background: red;*/
        padding-top: 15px;
    }
    &-title {
        display: block;
        color: #fafafa;
        margin-bottom: 8px;
    }
    &-links {
        margin: 0;
        padding: 0;
    }
    &-item {
        /*background: blue;*/
        display: block;
    }
    &-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        /*background: yellow;*/
        padding: 5px;
        color: #fafafa;
        &:hover {
            color: #fafafa;
        }
    }
    &-icon {
        flex: 1;
        color: #fafafa;
    }
    &-text {
        flex: 9;
    }
}

.show-item {
    margin-bottom: 10px;
    label {
        display: block;
        font-weight: bold;
    }
}

.document {
    border: 1px solid lighten($color_grey_light, 40%);
    margin: 50px 85px;
    border-radius: 3px;
    padding: 25px;
    &-terms {
        margin-top: 25px;
        margin-bottom: 0;
        ul {
            padding-left: 17px;
        }
        pre {
            margin: 0 0 5px 0;
            font-size: 12px;
        }
    }
}

.login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .panel {
        width: 25%;
    }
}

.image {
    max-width: 100%;
}