.w-12 {
    width: 100%;
}
.w-11 {
    width: 91.66666667%;
}
.w-10 {
    width: 83.33333333%;
}
.w-9 {
    width: 75%;
}
.w-8 {
    width: 66.66666667%;
}
.w-7 {
    width: 58.33333333%;
}
.w-6 {
    width: 50%;
}
.w-5 {
    width: 41.66666667%;
}
.w-4 {
    width: 33.33333333%;
}
.w-3 {
    width: 25%;
}
.w-2 {
    width: 16.66666667%;
}
.w-1 {
    width: 8.33333333%;
}