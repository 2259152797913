.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 12;
    background-color: rgba(0,0,0,0.4);
    overflow-y: scroll;
    &-heading {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $light_border;
    }
    &-search {
        width: 300px;
    }
    &-content {
        width: 80%;
        margin: 30px auto;
        background: white;
        border-radius: 2px;
    }
    &-title {
        font-size: 18px;
    }
    &-controls {

    }
    &-body {
        padding: 10px;
    }
    &-footer {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid $light_border;
    }
    &-footer_center {
        justify-content: space-between;
    }
}